/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { object, string, func } from 'prop-types';
import parseFontFamilyExtension from '../../../../helpers/contentstack/parseFontFamilyExtension';

const useStyles = makeStyles((theme) => ({
    countDownText: {
        display: 'flex',
        color: theme.palette.white || '#ffffff',
        fontFamily: 'inherit',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        width: '100%',
        [theme.breakpoints.down(1024)]: {
            fontSize: '1.125rem',
        },
    },
    countDownTime: {
        position: 'relative',
        display: 'grid',
        gridTemplateRows: 'auto',
        gridTemplateColumns: '38px 10px',
        gridTemplateAreas: '"time colon" "label ."',
        '&:last-of-type>$colon': {
            color: 'transparent',
        },
        [theme.breakpoints.down(680)]: {
            marginTop: '5px',
        },
    },
    countDownTimeText: {
        lineHeight: '18px',
        gridArea: 'time',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    colon: {
        gridArea: 'colon',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    countDownTimeLabel: {
        gridArea: 'label',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '0.75rem',
        fontWeight: '400',
        lineHeight: '10px',
        marginTop: '5px',
        [theme.breakpoints.down(1024)]: {
            fontSize: '0.563rem',
            marginTop: '3px',
        },
        [theme.breakpoints.down(680)]: {
            marginTop: '2px',
        },
    },
}));
const CountDownTimer = ({ dateCountDown, styling, hideBanner }) => {
    const classes = useStyles(styling);
    const countDownDate = new Date(dateCountDown).getTime();
    const [state, setState] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        let timeoutId;
        const updateCountdown = () => {
            const nowTime = new Date().getTime();
            // Get the time remaining until the countdown date
            const distanceToDate = countDownDate - nowTime;
            const days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

            if ((days + hours + minutes + seconds) <= 0) { hideBanner(); }
            setState({
                days: String(days).padStart(2, '0'),
                hours: String(hours).padStart(2, '0'),
                minutes: String(minutes).padStart(2, '0'),
                seconds: String(seconds).padStart(2, '0'),
            });
            timeoutId = setTimeout(updateCountdown, 1000);
        };
        if (countDownDate) {
            updateCountdown();
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [countDownDate, hideBanner]);

    const renderContent = (value, label) => (
        <div className={classes.countDownTime}>
            <span className={classes.countDownTimeText}>{value}</span><span className={classes.colon}><div>:</div></span>
            <span className={classes.countDownTimeLabel}>{label}</span>
        </div>
    );
    return (

        <div
            className={classes.countDownText}
            style={{
                color: styling?.font_color?.color,
                fontFamily: parseFontFamilyExtension(styling?.font),
            }}
        >
            {state.days !== 0 && (
                renderContent(state?.days, 'DAYS')
            )}
            {renderContent(state?.hours || '00', 'HRS')}
            {renderContent(state?.minutes || '00', 'MINS')}
            {renderContent(state?.seconds || '00', 'SECS')}
        </div>
    );
};
CountDownTimer.propTypes = {
    styling: object.isRequired,
    dateCountDown: string.isRequired,
    hideBanner: func.isRequired,
};
export default CountDownTimer;
