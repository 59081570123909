/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import replaceSpecialChar from '../replaceSpecialChar';

const cleanText = (str) => {
    const string = replaceSpecialChar(str, false);
    const re = /<p>|<\/p>|\\n/g; // strips <p>,</p> and \n
    const copy = string?.replace(re, '');

    if (copy === '<br>') return ''; // CMS RTE often returns a solitary <br>. This can throw !length logic checks off.
    return copy;
};

export default cleanText;
